<template>
  <div class="cookies-container">
    <div class="content">
      <h1>Zásady používania cookies</h1>

      <section>
        <h2>1. Úvod</h2>
        <p>
          Táto stránka používa súbory cookies s&nbsp;cieľom zabezpečiť čo najlepší zážitok pre našich návštevníkov. V&nbsp;tejto
          časti vám vysvetlíme, čo sú cookies, ako ich používame, a&nbsp;aké možnosti máte, pokiaľ ide o&nbsp;ich správu.
        </p>
      </section>

      <section>
        <h2>2. Čo sú súbory cookies?</h2>
        <p>
          Cookies sú malé textové súbory, ktoré sú uložené na vašom zariadení (počítači, tablete, smartfóne) počas
          návštevy webových stránok. Umožňujú webovej stránke zapamätať si vaše akcie a&nbsp;preferencie (napr.
          prihlasovacie údaje, jazyk, veľkosť písma a&nbsp;ďalšie zobrazovacie preferencie) po určitý čas, takže ich
          nemusíte zadávať vždy nanovo pri návrate na stránku alebo prehliadaní rôznych stránok.
        </p>
      </section>

      <section>
        <h2>3. Ako používame súbory cookies?</h2>
        <p>Na našej stránke používame cookies na nasledujúce účely:</p>
        <ul>
          <li>
            <b>Nevyhnutné cookies:</b> Tieto cookies sú nevyhnutné pre správne fungovanie webovej stránky. Umožňujú vám
            navigáciu po stránke a&nbsp;používanie jej základných funkcií.
          </li>
          <li>
            <b>Analytické cookies:</b> Tieto cookies nám pomáhajú pochopiť, ako návštevníci interagujú s&nbsp;našou
            webovou stránkou. Informácie sa zbierajú anonymne a&nbsp;slúžia na zlepšenie funkčnosti našej stránky.
          </li>
          <li>
            <b>Preferenčné cookies:</b> Ukladajú vaše preferencie, ako napríklad jazyk alebo región, v&nbsp;ktorom sa
            nachádzate, čo zlepšuje váš používateľský zážitok.
          </li>
          <li>
            <b>Marketingové cookies:</b> Tieto cookies sa používajú na sledovanie návštevníkov naprieč webovými
            stránkami. Cieľom je zobrazovať reklamy, ktoré sú relevantné a&nbsp;zaujímavé pre jednotlivých používateľov,
            a&nbsp;tým hodnotnejšie pre vydavateľov a&nbsp;tretie strany inzerentov.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Cookies tretích strán</h2>
        <p>
          Na našej stránke môžeme používať aj cookies, ktoré poskytujú tretie strany. Tieto cookies môžu sledovať vaše
          používanie iných webových stránok a&nbsp;ponúkať vám personalizovaný obsah alebo reklamy.
        </p>
      </section>

      <section>
        <h2>5. Ako spravovať súbory cookies?</h2>
        <p>
          Väčšina webových prehliadačov umožňuje kontrolu cookies prostredníctvom nastavení prehliadača. Môžete si
          nastaviť prehliadač tak, aby cookies blokoval alebo aby vás upozornil, keď sa pokúša webová stránka cookies
          umiestniť. Upozorňujeme, že ak sa rozhodnete blokovať všetky cookies, môže to ovplyvniť funkčnosť našej
          webovej stránky.
        </p>
        <p>Nižšie nájdete odkazy na návody, ako spravovať cookies v&nbsp;bežných prehliadačoch:</p>
        <ul>
          <li>
            <a href="https://policies.google.com/technologies/cookies?hl=en-US" target="_blank">Google Chrome</a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
               target="_blank">
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac" target="_blank">Safari</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
               target="_blank">
              Microsoft Edge
            </a>
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Zmeny v&nbsp;zásadách používania cookies</h2>
        <p>
          Môžeme z&nbsp;času na čas aktualizovať tieto zásady používania cookies, aby sme reflektovali zmeny v&nbsp;technológiách
          alebo legislatíve. O&nbsp;zmenách vás budeme informovať prostredníctvom oznámení na našej webovej stránke.
        </p>
      </section>

      <section>
        <h2>7. Kontakt</h2>
        <p>
          Ak máte akékoľvek otázky ohľadom používania cookies na našej stránke, neváhajte nás kontaktovať na <a
            href="mailto:info@citro.sk">info@citro.sk</a>.
        </p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.cookies-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.content h2 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #555;
}

.content p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

.content ul {
  padding-left: 20px;
  list-style-type: disc;
  color: #666;
}

.content ul li {
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}
</style>
