<template>
  <div v-if="!accepted" class="cookie-consent">
    <p>
      Táto stránka používa cookies pre zlepšenie vášho zážitku. Pokračovaním na stránke súhlasíte s&nbsp;ich používaním.
    </p>
    <div class="actions">
      <button @click="acceptCookies">Súhlasím</button>
      <router-link to="/cookies" class="info-link">Viac informácií</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      accepted: false,
    };
  },
  mounted () {
    this.accepted = localStorage.getItem('cookiesAccepted') === 'true';
  },
  methods: {
    acceptCookies () {
      this.accepted = true;
      localStorage.setItem('cookiesAccepted', 'true');
    },
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 90%;
}

.cookie-consent p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.actions {
  display: flex;
  gap: 10px;
}

.cookie-consent button {
  background-color: #4caf50; /* Modern green color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.cookie-consent button:hover {
  background-color: #43a047; /* Darker green on hover */
}

.cookie-consent .info-link {
  color: #ffffff;
  text-decoration: underline;
  font-size: 14px;
  transition: color 0.3s ease;
}

.cookie-consent .info-link:hover {
  color: #4caf50; /* Link turns green on hover */
}
</style>
