<template>
  <div class="container">
    <h2>Košík</h2>
    <div v-if="cart.length === 0">
      <p>Tvoj košík je prázdny.</p>
    </div>
    <div v-else>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Obrázok</th>
          <th scope="col">Produkt</th>
          <th scope="col">Množstvo</th>
          <th scope="col">Cena</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in cart" :key="index">
          <td><img :src="`/images/${item.image}`" :alt="item.name" class="img-fluid" style="max-width: 100px;" /></td>
          <td>
            <h5>{{ item.name }}</h5>
            <p>{{ item.description }}</p>
          </td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.price.toFixed(2).replace('.', ',') }}&nbsp;&euro;</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="removeFromCart(item)">Odstrániť</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="cart.length > 0" class="mt-3">
      <h3 class="h5 m-0">Celkovo: {{ totalPrice.toFixed(2).replace('.', ',') }}&nbsp;&euro;</h3>
      <h3 class="h5 m-0">Doprava: {{ shippingPrice.toFixed(2).replace('.', ',') }}&nbsp;&euro; - Slovenská pošta</h3>
      <h3>Celková suma: {{ (totalPrice + shippingPrice).toFixed(2).replace('.', ',') }}&nbsp;&euro;</h3>
      <button class="btn btn-primary" @click="goToNextStep">Prejsť na objednanie</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['cart', 'totalPrice', 'shippingPrice']),
  },
  methods: {
    ...mapActions(['clearCart']),
    ...mapActions(['removeFromCart']),
    goToNextStep () {
      this.$router.push('/objednavka');
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #fffbe0; /* Light lemon background */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 0 auto;
}

h2 {
  color: #4c2c20; /* Coffee brown */
  margin-bottom: 1.5rem;
}

.table {
  background-color: #ffffff; /* White background for table */
  border-radius: 8px;
  overflow: hidden;
}

th {
  color: #4c2c20; /* Coffee brown for table headers */
  background-color: #f7e319; /* Lemon color for table header background */
  text-align: center;
  padding: 1rem;
}

td {
  color: #333; /* Dark brown for table text */
  text-align: center;
  padding: 1rem;
}

.img-fluid {
  border-radius: 8px;
  border: 2px solid #f7e319; /* Lemon border around images */
}

h5 {
  color: #4c2c20; /* Coffee brown for product names */
}

p {
  color: #666; /* Light brown for product descriptions */
  font-size: 0.9rem;
}

.btn-danger {
  background-color: #af6e2e; /* Coffee color for remove button */
  border: none;
  font-weight: bold;
}

.btn-danger:hover {
  background-color: #4c2c20; /* Darker coffee color on hover */
}

.btn-primary {
  background-color: #f7e319; /* Lemon color for proceed button */
  color: #4c2c20; /* Coffee color for text */
  border: none;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: #af6e2e; /* Darker coffee color on hover */
  color: #fff;
}

.mt-3 h3 {
  color: #4c2c20; /* Coffee brown for total price */
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
</style>