import { createStore } from 'vuex';

const loadCartFromLocalStorage = () => {
    try {
        const cart = localStorage.getItem('cart');
        return cart ? JSON.parse(cart) : [];
    } catch (error) {
        console.error('Failed to load cart from localStorage', error);
        return [];
    }
};

const saveCartToLocalStorage = (cart) => {
    try {
        localStorage.setItem('cart', JSON.stringify(cart));
    } catch (error) {
        console.error('Failed to save cart to localStorage', error);
    }
};

export default createStore({
    state() {
        return {
            products: [],
            cart: loadCartFromLocalStorage(), // Load cart from localStorage
            checkout: {
                user: {}
            },
            alert: { // New state for alert messages
                message: '',
                type: '', // Can be 'success', 'warning', 'danger', etc.
                visible: false
            }
        };
    },
    mutations: {
        SET_PRODUCTS(state, products) {
            state.products = products;
        },
        ADD_TO_CART(state, product) {
            const existingProduct = state.cart.find(item => item.id === product.id);
            const productInStore = state.products.find(item => item.id === product.id);

            if (existingProduct) {
                // Check if adding one more exceeds the available quantity
                if (existingProduct.quantity < productInStore.quantity) {
                    existingProduct.quantity += 1;
                    this.commit('SET_ALERT', { message: `${product.name} bol pridaný do košíka.`, type: 'success' });
                } else {
                    this.commit('SET_ALERT', { message: `Nemôžete pridať viac než ${productInStore.quantity} kusov produktu ${product.name} do košíka.`, type: 'warning' });
                }
            } else {
                // Only add if quantity in store is at least 1
                if (productInStore.quantity > 0) {
                    state.cart.push({ ...product, quantity: 1 });
                    this.commit('SET_ALERT', { message: `${product.name} bol pridaný do košíka.`, type: 'success' });
                } else {
                    this.commit('SET_ALERT', { message: `Produkt ${product.name} nie je na sklade.`, type: 'danger' });
                }
            }
            saveCartToLocalStorage(state.cart); // Save cart to localStorage
        },
        REMOVE_FROM_CART(state, product) {
            const existingProductIndex = state.cart.findIndex(item => item.id === product.id);
            if (existingProductIndex !== -1) {
                const existingProduct = state.cart[existingProductIndex];
                if (existingProduct.quantity > 1) {
                    existingProduct.quantity -= 1;
                } else {
                    state.cart.splice(existingProductIndex, 1);
                }
                saveCartToLocalStorage(state.cart);
            }
        },
        CLEAR_CART(state) {
            state.cart = [];
            saveCartToLocalStorage(state.cart); // Save empty cart to localStorage
        },
        UPDATE_USER(state, user) {
            state.checkout.user = user;
        },
        TOGGLE_AGREEMENTS(state) {
            state.checkout.agreements = !state.checkout.agreements;
        },
        SET_ALERT(state, alert) { // New mutation to set alert message
            state.alert = { ...alert, visible: true };
        },
        CLEAR_ALERT(state) { // New mutation to clear alert
            state.alert.visible = false;
            state.alert.message = '';
            state.alert.type = '';
        }
    },
    actions: {
        async fetchProducts({ commit }) {
            try {
                const response = await fetch('/data/products.json');
                const products = await response.json();
                commit('SET_PRODUCTS', products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        },
        addToCart({ commit }, product) {
            commit('ADD_TO_CART', product);
        },
        removeFromCart({ commit }, product) {
            commit('REMOVE_FROM_CART', product);
        },
        clearCart({ commit }) {
            commit('CLEAR_CART');
        },
        updateUser({ commit }, user) {
            commit('UPDATE_USER', user);
        },
        clearAlert({ commit }) { // Action to clear alert
            commit('CLEAR_ALERT');
        }
    },
    getters: {
        allProducts(state) {
            return state.products;
        },
        cart(state) {
            return state.cart;
        },
        shippingPrice() {
            return 5.00; // Pevná cena dopravy Slovenskou poštou
        },
        totalPrice(state) {
            return state.cart.reduce((total, item) => total + item.price * item.quantity, 0);
        },
        userDetails(state) {
            return state.checkout.user;
        },
        alert(state) {
            return state.alert; // Getter for alert state
        }
    }
});
