<template>
  <footer class="footer">
    <p>
      <router-link to="/obchodne-podmienky">Obchodné podmienky</router-link>
      |
      <router-link to="/ochrana-osobnych-udajov">Ochrana osobných údajov</router-link>
    </p>
    <p>&copy; 2024 Citro s.r.o. | Všetky práva vyhradené.</p>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #4b3f33; /* Coffee brown background color */
  color: #fffbe0; /* Lemon cream text color */
  padding: 1rem;
  text-align: center;
}

.footer a {
  color: #f7e319; /* Lemon color for footer links */
  text-decoration: none;
}
</style>
