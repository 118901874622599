import {createRouter, createWebHistory} from 'vue-router';
import Products from '../views/Products.vue';
import Cart from '../components/Cart.vue';
import Checkout from '../components/Checkout.vue';
import Terms from '../views/Terms.vue';
import Home from '../views/Home.vue';
import GDPR from '../views/GDPR.vue';
import Cookies from '../views/Cookies.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const routes = [
    {path: '/', component: Home},
    {path: '/produkty', component: Products},
    {path: '/kosik', component: Cart},
    {path: '/objednavka', component: Checkout},
    {path: '/obchodne-podmienky', component: Terms},
    {path: '/ochrana-osobnych-udajov', component: GDPR},
    {path: '/cookies', component: Cookies},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
