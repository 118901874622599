<template>
  <div class="gdpr-container">
    <div class="content">
      <h1>Ochrana osobných údajov</h1>

      <section>
        <h2>1. Úvod</h2>
        <p>
          Vaše súkromie je pre nás veľmi dôležité. Tento dokument obsahuje informácie o&nbsp;tom, akým spôsobom zhromažďujeme, používame a&nbsp;chránime vaše osobné údaje, keď používate naše služby alebo navštevujete našu webovú stránku.
        </p>
      </section>

      <section>
        <h2>2. Zhromažďovanie osobných údajov</h2>
        <p>
          Zhromažďujeme len také osobné údaje, ktoré sú nevyhnutné pre poskytovanie našich služieb. Môžeme zhromažďovať nasledujúce údaje:
        </p>
        <ul>
          <li>Meno a&nbsp;priezvisko</li>
          <li>Emailová adresa</li>
          <li>Telefónne číslo</li>
          <li>Adresa</li>
          <li>Údaje o&nbsp;vašom správaní na našej webovej stránke (cookies, IP adresa)</li>
        </ul>
        <p>
          Tieto údaje zhromažďujeme priamo od vás, keď vyplníte formuláre na našej webovej stránke, alebo automaticky prostredníctvom technológií, ako sú cookies.
        </p>
      </section>

      <section>
        <h2>3. Účel spracovania osobných údajov</h2>
        <p>Vaše osobné údaje spracovávame na nasledujúce účely:</p>
        <ul>
          <li>Poskytovanie a&nbsp;zlepšovanie našich služieb</li>
          <li>Komunikácia s&nbsp;vami</li>
          <li>Marketingové aktivity, ako sú zasielanie newsletterov (iba s&nbsp;vaším súhlasom)</li>
          <li>Analytické účely s&nbsp;cieľom lepšie pochopiť vaše potreby a&nbsp;správanie</li>
        </ul>
      </section>

      <section>
        <h2>4. Zdieľanie osobných údajov</h2>
        <p>
          Vaše údaje nepredávame ani neposkytujeme tretím stranám, s&nbsp;výnimkou prípadov, keď je to nevyhnutné na poskytovanie našich služieb (napr. dodávateľom IT služieb) alebo keď to vyžaduje zákon.
        </p>
      </section>

      <section>
        <h2>5. Ochrana osobných údajov</h2>
        <p>
          Implementujeme vhodné technické a&nbsp;organizačné opatrenia na zabezpečenie vašich osobných údajov pred neoprávneným prístupom, zneužitím, stratou alebo zničením.
        </p>
      </section>

      <section>
        <h2>6. Vaše práva</h2>
        <p>
          Máte právo na prístup k&nbsp;vašim osobným údajom, ich opravu, vymazanie, obmedzenie spracovania, prenosnosť údajov a&nbsp;právo namietať voči spracovaniu. Ak si prajete uplatniť tieto práva, kontaktujte nás na <a href="mailto:info@citro.sk">info@citro.sk</a>.
        </p>
      </section>

      <section>
        <h2>7. Súbory cookies</h2>
        <p>
          Na našej webovej stránke používame súbory cookies na zlepšenie vášho užívateľského zážitku. Viac informácií o&nbsp;tom, ako používame cookies a&nbsp;ako ich môžete spravovať, nájdete v&nbsp;našich <router-link to="/cookies">Zásadách používania cookies</router-link>.
        </p>
      </section>

      <section>
        <h2>8. Zmeny v&nbsp;politike ochrany osobných údajov</h2>
        <p>
          Môžeme občas aktualizovať túto politiku ochrany osobných údajov. Ak vykonáme významné zmeny, oznámime vám to prostredníctvom emailu alebo upozornenia na našej webovej stránke.
        </p>
      </section>

      <section>
        <h2>9. Kontakt</h2>
        <p>
          Ak máte akékoľvek otázky alebo obavy týkajúce sa tejto politiky ochrany osobných údajov, kontaktujte nás na: <a href="mailto:info@citro.sk">info@citro.sk</a> alebo <a href="tel:+421902886162">+421 902 886 162</a>.
        </p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.gdpr-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.content h2 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #555;
}

.content p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

.content ul {
  padding-left: 20px;
  list-style-type: disc;
  color: #666;
}

.content ul li {
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}
</style>
