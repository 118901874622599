<template>
  <div class="checkout-container">
    <h2 class="title">Objednávka</h2>

    <!-- Cart Summary Table -->
    <div v-if="cart.length > 0" class="summary">
      <h3 class="summary-title">Zhrnutie objednáky</h3>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Produkt</th>
          <th scope="col">Množstvo</th>
          <th scope="col">Cena za kus</th>
          <th scope="col">Cena</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in cart" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.price.toFixed(2).replace('.', ',') }}&nbsp;&euro;</td>
          <td>{{ (item.price * item.quantity).toFixed(2).replace('.', ',') }}&nbsp;&euro;</td>
        </tr>
        </tbody>
      </table>

      <!-- Total Price -->
      <div class="totals">
        <h3 class="h5 m-0">Suma: {{ totalPrice.toFixed(2).replace('.', ',') }}&nbsp;&euro;</h3>
        <h3 class="h5 m-0">Doprava: {{ shippingPrice.toFixed(2).replace('.', ',') }}&nbsp;&euro; - Slovenská pošta</h3>
        <h3 class="total-price">Celková suma: {{ (totalPrice + shippingPrice).toFixed(2).replace('.', ',') }}&nbsp;&euro;</h3>
      </div>
    </div>

    <!-- User Information Form -->
    <form @submit.prevent="submitOrder" class="user-info">
      <h3 class="form-title">Údaje o&nbsp;zákazníkovi</h3>
      <div class="form-group">
        <label for="name">Meno a&nbsp;priezvisko</label>
        <input type="text" id="name" v-model="user.name" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="email">E-mail</label>
        <input type="email" id="email" v-model="user.email" class="form-control" required />
      </div>
      <div class="form-group form-check">
        <input type="checkbox" id="agreements" v-model="agreements" class="form-check-input" />
        <label for="agreements" class="form-check-label">
          Súhlasím s&nbsp;<router-link to="/obchodne-podmienky" target="_blank">obchodnými podmienkami</router-link>
        </label>
      </div>
      <button class="btn-submit" type="submit" :disabled="!agreements">Záväzne objednať</button>
    </form>

    <!-- Alert Message -->
    <div v-if="showAlert" class="alert" role="alert">
      {{ alertMessage }}
      <button type="button" class="btn-close" @click="showAlert = false" aria-label="Close"></button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      user: {
        name: '',
        email: ''
      },
      agreements: false,
      showAlert: false,
      alertMessage: ''
    };
  },
  computed: {
    ...mapGetters(['cart', 'totalPrice', 'shippingPrice']),
  },
  methods: {
    ...mapActions(['clearCart']),
    ...mapMutations(['UPDATE_USER', 'TOGGLE_AGREEMENTS']),
    async submitOrder () {
      this.UPDATE_USER(this.user);
      this.TOGGLE_AGREEMENTS();

      const response = await fetch('/send_order.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cart: this.$store.state.cart,
          user: this.$store.state.checkout.user
        })
      });

      if (response.ok) {
        this.alertMessage = 'Objednávka je odoslaná.';
        this.showAlert = true;
        this.$store.commit('CLEAR_CART');
        await this.clearCart();
        this.$router.push('/');
      } else {
        this.alertMessage = 'Chyba pri spracovaní objednávky.';
        this.showAlert = true;
      }
    }
  }
};
</script>

<style scoped>
.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.summary {
  margin-bottom: 40px;
}

.summary-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.totals h3 {
  font-weight: normal;
}

.total-price {
  font-size: 20px;
  color: #d9534f;
  font-weight: bold;
  margin-top: 10px;
}

.user-info {
  margin-bottom: 30px;
}

.form-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.btn-submit {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-submit:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.alert {
  padding: 15px;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
  border-radius: 4px;
  position: relative;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
