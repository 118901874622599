<template>
  <div class="terms-container">
    <h1>Všeobecné obchodné podmienky</h1>

    <h2>1. Úvodné ustanovenia</h2>

    <p>
      1.1 Tieto obchodné podmienky (ďalej len „obchodné podmienky“) upravujú práva a&nbsp;povinnosti medzi predávajúcim a&nbsp;kupujúcim
      pri nákupe tovaru v&nbsp;internetovom obchode predávajúceho (ďalej len „e-shop“).
    </p>

    <p>
      1.2 Predávajúcim je Citro s.r.o., so sídlom Karpatské námestie 10A, 83106 Bratislava, IČO: 51800616, DIČ:
      2120798779, zapísaný v&nbsp;Obchodnom registri Mestského súdu Bratislava III.<br>
      Kontaktné údaje: e-mail: info@citro.sk, telefón: 0902 886 162 (ďalej len „predávajúci“).
    </p>

    <p>
      1.3 Kupujúcim je fyzická alebo právnická osoba, ktorá uzavrie s&nbsp;predávajúcim kúpnu zmluvu prostredníctvom
      e-shopu (ďalej len „kupujúci“).
    </p>

    <h2>2. Objednávka a&nbsp;uzatvorenie kúpnej zmluvy</h2>

    <p>
      2.1 Kupujúci vykoná objednávku tovaru prostredníctvom e-shopu vyplnením objednávkového formulára. Odoslaním
      objednávky kupujúci potvrdzuje, že sa oboznámil s&nbsp;týmito obchodnými podmienkami a&nbsp;súhlasí s&nbsp;nimi.
    </p>

    <p>
      2.2 Kúpna zmluva je uzavretá okamihom, keď predávajúci potvrdí prijatie objednávky elektronickou poštou na e-mailovú
      adresu uvedenú kupujúcim.
    </p>

    <p>
      2.3 Predávajúci si vyhradzuje právo odmietnuť objednávku, ak nie je schopný zabezpečiť dodanie tovaru. O&nbsp;odmietnutí
      objednávky bude kupujúci informovaný e-mailom.
    </p>

    <h2>3. Cena a&nbsp;platobné podmienky</h2>

    <p>
      3.1 Cena tovaru je uvedená v&nbsp;e-shope pri jednotlivých produktoch a&nbsp;je konečná, vrátane všetkých daní a&nbsp;poplatkov.
    </p>

    <p>
      3.2 Kupujúci je povinný zaplatiť cenu za tovar pred dodaním tovaru a&nbsp;to bankovým prevodom na účet
      predávajúceho. Údaje na platbu budú zaslané kupujúcemu e-mailom spolu s&nbsp;potvrdením objednávky.
    </p>

    <p>
      3.3 Platba sa považuje za vykonanú okamihom pripísania celej sumy na účet predávajúceho. Po prijatí platby vystaví
      predávajúci kupujúcemu faktúru, ktorá bude zaslaná elektronickou formou na e-mailovú adresu kupujúceho.
    </p>

    <h2>4. Dodacie podmienky</h2>

    <p>
      4.1 Predávajúci zabezpečuje dopravu tovaru prostredníctvom Slovenskej pošty na adresu uvedenú kupujúcim v&nbsp;objednávke.
    </p>

    <p>
      4.2 Dodacia lehota je spravidla 3-5 pracovných dní od prijatia platby. V&nbsp;prípade, že tovar nie je možné doručiť
      v&nbsp;tejto lehote, predávajúci informuje kupujúceho o&nbsp;predĺžení dodacej lehoty.
    </p>

    <p>
      4.3 Náklady na dopravu znáša kupujúci a&nbsp;sú uvedené v&nbsp;objednávkovom formulári. V&nbsp;prípade objednávky
      nad 80&nbsp;&euro; je doprava zdarma.
    </p>

    <p>
      4.4 Kupujúci je povinný prevziať tovar pri doručení. Ak kupujúci odmietne prevziať tovar bez oprávneného dôvodu, je
      povinný uhradiť náklady na vrátenie tovaru predávajúcemu.
    </p>

    <h2>5. Odstúpenie od zmluvy</h2>

    <p>
      5.1 Kupujúci má právo odstúpiť od zmluvy bez udania dôvodu do 14 dní odo dňa prevzatia tovaru. Kupujúci je povinný
      písomne informovať predávajúceho o&nbsp;odstúpení od zmluvy e-mailom.
    </p>

    <p>
      5.2 Tovar musí byť vrátený nepoškodený, v&nbsp;pôvodnom obale a&nbsp;bez známok použitia na adresu predávajúceho do
      14 dní odo dňa odstúpenia od zmluvy. Náklady na vrátenie tovaru znáša kupujúci.
    </p>

    <p>
      5.3 Predávajúci vráti kupujúcemu sumu zaplatenú za tovar do 14 dní odo dňa prijatia vráteného tovaru na bankový účet
      kupujúceho.
    </p>

    <h2>6. Reklamačný poriadok</h2>

    <p>
      6.1 Kupujúci má právo reklamovať vady tovaru do 24 mesiacov od jeho prevzatia. Reklamáciu je potrebné uplatniť
      písomne na adresu predávajúceho alebo e-mailom.
    </p>

    <p>
      6.2 Predávajúci je povinný posúdiť reklamáciu do 30 dní odo dňa jej prijatia a&nbsp;informovať kupujúceho o&nbsp;výsledku.
    </p>

    <p>
      6.3 V&nbsp;prípade oprávnenej reklamácie má kupujúci nárok na opravu alebo výmenu tovaru, prípadne na primeranú
      zľavu z&nbsp;ceny alebo vrátenie kúpnej ceny.
    </p>

    <h2>7. Ochrana osobných údajov</h2>

    <p>
      7.1 Predávajúci spracúva osobné údaje kupujúceho v&nbsp;súlade so zákonom o&nbsp;ochrane osobných údajov. Osobné
      údaje budú použité výlučne na účely plnenia kúpnej zmluvy a&nbsp;nebudú poskytnuté tretím stranám, s&nbsp;výnimkou
      prepravných spoločností zabezpečujúcich dodanie tovaru.
    </p>

    <p>
      7.2 Kupujúci má právo na prístup k&nbsp;svojim osobným údajom, ich opravu alebo vymazanie.
    </p>

    <h2>8. Záverečné ustanovenia</h2>

    <p>
      8.1 Tieto obchodné podmienky sú platné a&nbsp;účinné od 1.9.2024 a&nbsp;nahrádzajú všetky predchádzajúce obchodné
      podmienky.
    </p>

    <p>
      8.2 Predávajúci si vyhradzuje právo kedykoľvek zmeniť tieto obchodné podmienky. Zmeny obchodných podmienok budú
      zverejnené na internetovej stránke predávajúceho.
    </p>

    <p>
      8.3 Akékoľvek spory vzniknuté z&nbsp;týchto obchodných podmienok budú riešené predovšetkým dohodou. V&nbsp;prípade,
      že sa spory nepodarí vyriešiť dohodou, budú riešené pred príslušným súdom Slovenskej republiky.
    </p>
  </div>
</template>

<style scoped>
.terms-container {
  background-color: #fffbe0; /* Light lemon background */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333; /* Dark brown text for readability */
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  font-size: 2rem;
  color: #4c2c20; /* Coffee brown color */
  text-align: center;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  color: #4c2c20; /* Coffee brown color */
  margin-top: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

a {
  color: #af6e2e; /* Slightly lighter coffee color for links */
  text-decoration: underline;
}

p br {
  display: none;
}
</style>
