<template id="app">
  <Navbar/>
  <router-view />
  <CookieConsent />
  <Footer/>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    CookieConsent,
  },
};
</script>

<style>
/* Global styles */
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4b3f33; /* Coffee brown text color */
  margin-top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f7e319; /* Lemon color for scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #4b3f33; /* Coffee brown color for scrollbar thumb */
  border-radius: 5px;
}

a {
  color: #f7e319; /* Lemon color for links */
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #4b3f33; /* Coffee brown color for headers */
}

footer {
  margin-top: auto;
}
</style>