<template>
  <div class="home-container">
    <div class="hero-section">
      <img src="/images/kava5.jpg" alt="Citro káva" class="hero-image">
      <div class="hero-text">
        <h1>Citro káva</h1>
        <p>Nezabudnuteľná chuť, ktorá pretrvá.</p>
        <p>Osviežte svoj deň citro-kávovou harmóniou.</p>
        <p>Káva, ktorá prebúdza s&nbsp;citrovým závanom.</p>
        <p>Citro káva – nečakaná, nezabudnuteľná.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.hero-section {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px); /* Full screen height minus possible header */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(70%); /* Darkens the image for better text contrast */
}

.hero-text {
  position: absolute;
  color: #fff;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 10px;
}

.hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 32px;
  }

  .hero-text p {
    font-size: 16px;
  }
}
</style>
