<template>
  <nav class="navbar">
    <div class="logo">
      <router-link to="/">🍋 Citro</router-link>
    </div>
    <ul class="nav-links">
      <li>
        <router-link to="/produkty">Produkty</router-link>
      </li>
      <li>
        <router-link to="/kosik">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
          Košík
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.navbar {
  background-color: #f7e319; /* Lemon background color */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #4b3f33; /* Coffee brown border at the bottom */
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: #4b3f33; /* Coffee brown color for links */
  font-weight: bold;
}

.nav-links a:hover {
  color: #2e2a26; /* Darker coffee brown for hover effect */
  text-decoration: underline;
}

.logo a {
  color: #4b3f33; /* Coffee brown color for logo text */
}
</style>