<template>
  <div class="container products">
    <h2 class="my-4">Produkty</h2>

    <!-- Bootstrap alert component -->
    <div v-if="alert.visible" :class="`alert alert-${alert.type} alert-dismissible fade show`" role="alert">
      {{ alert.message }}
      <button type="button" class="btn-close" @click="clearAlert" aria-label="Close"></button>
    </div>

    <div v-if="allProducts.length === 0">
      <p>Načítavam produkty...</p>
    </div>
    <div class="row" v-else>
      <div class="col-md-4" v-for="product in allProducts" :key="product.id">
        <div class="card mb-4 shadow-sm">
          <img :src="`/images/${product.image}`" class="card-img-top" :alt="product.name" />
          <div class="card-body">
            <h5 class="card-title">{{ product.name }}</h5>
            <p class="card-text">{{ product.description }}</p>
            <p class="card-text">Cena: {{ product.price }}&nbsp;&euro;</p>
            <button @click="handleAddToCart(product)" class="btn btn-warning">Pridať do košíka</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      currentProduct: null,
    };
  },
  computed: {
    ...mapGetters(['allProducts', 'alert']),
  },
  methods: {
    ...mapActions(['addToCart', 'clearAlert']),
    handleAddToCart(product) {
      this.currentProduct = product;
      this.addToCart(product);
      // Alert automatically updates from Vuex
      setTimeout(this.clearAlert, 5000);
    },
    async fetchProducts() {
      await this.$store.dispatch('fetchProducts');
    }
  },
  mounted() {
    this.fetchProducts();
  }
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
}

h2 {
  color: #4b3f33; /* Coffee brown for header */
  text-align: center;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.alert {
  background-color: #f7e319; /* Lemon color for alert */
  color: #4b3f33; /* Coffee brown text in alert */
  border-color: #f7e319;
  text-align: center;
  font-weight: bold;
}

.card {
  background-color: #f7e319; /* Lemon background for cards */
  border: none;
  border-radius: 8px;
}

.card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-body {
  color: #4b3f33; /* Coffee brown text in card */
  text-align: left;
}

.card-title {
  color: #4b3f33; /* Coffee brown for card title */
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-text {
  color: #4b3f33; /* Coffee brown for card text */
  margin-bottom: 1rem;
}

.btn-warning {
  background-color: #4b3f33; /* Coffee brown background for button */
  color: #fffbe0; /* Light lemon text on button */
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.btn-warning:hover {
  background-color: #3e352b; /* Darker coffee brown on hover */
  color: #fffbe0; /* Light lemon text on button */
}
</style>
